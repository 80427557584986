<template>
  <div class="card">
    <div class="card-body">
      <div class="row g-2">
        <div class="col-12 center">
          <div   class="center">
            <button class="btn btn-outline-primary btn-load ">
        <span class="d-flex align-items-center">
            <span class="spinner-border flex-shrink-0" role="status">
                <span class="visually-hidden">Loading...</span>
            </span>
            <span class="flex-grow-1 ms-2">
                Loading...
            </span>
        </span>
            </button>
            <button class="btn btn-success btn-load" type="button">
                    <span class="d-flex align-items-center">
                        <span class="spinner-border flex-shrink-0" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </span>
                        <span class="flex-grow-1 ms-2">
                            Loading...
                        </span>
                    </span>
            </button>
            <button class="btn btn-outline-secondary btn-load btn-lg " type="button">
                    <span class="d-flex align-items-center">
                        <span class="spinner-grow flex-shrink-0" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </span>
                        <span class="flex-grow-1 ms-2">
                            Loading...
                        </span>
                    </span>
            </button>
            <button class="btn btn-danger btn-load" type="button">
                    <span class="d-flex align-items-center">
                        <span class="spinner-grow flex-shrink-0" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </span>
                        <span class="flex-grow-1 ms-2">
                            Loading...
                        </span>
                    </span>
            </button>
          </div>

        </div>
        <!--end col-->


      </div>
      <!--end row-->
    </div>
  </div>
</template>

<script>
export default {
  name: "page-loader-one"
}
</script>


<style scoped>


.center {
  text-align: center;

}

</style>
