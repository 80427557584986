<script>
import Layout from "@/layouts/main.vue";
import appConfig from "@/app.config";
import "@vueform/multiselect/themes/default.css";
import PageHeader from "@/components/page-header";
import animationData from "@/components/widgets/msoeawqm.json";
import Lottie from "@/components/widgets/lottie.vue";

import Multiselect from "@vueform/multiselect";
import exportExcel from "@/helpers/exportExcel.js";
import pageLoaderOne from "@/components/page-loader-one";

export default {
  page: {
    title: "Region Stat",
    meta: [
      {
        name: "Region Stat",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      modalTitle: "",
      fileList: {},
      checkAll: false,
      title: this.$route.params.region ? this.$route.params.region + " Region" :"List",
      items: [
        {
          text: "Region Stat",
          href: "/",
        },
        {
          text: this.$route.params.region ? this.$route.params.region + " Region" :"List",
          active: true,
        },
      ],
      searchQuery: null,
      defaultOptions: {
        animationData: animationData,
      },
      sliderCustomzie: [1000, 3000],
      userData: [],
      pages: [],
      page: 1,

      UserDetailsData: {},
      formData: {
        name: "",

        avatar: "",
        phone: "",

        email: "",
        role_id: "",
      },
      table_options: {
        current_page: 1,
        page_size: 20,
        search_text: "",
        sort: { column: "", direction: "" },
      },
      meta: {},

      regionStats: [],
      regionSummaryStats: {},

      //loader
      loadingPage: true,
    };
  },

  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    //EXPORT USERS
    exportUsers() {
      this.$store.dispatch("result/exportResults").then((res) => {
        exportExcel(res, "user-list.xlsx");
      });
    },

    //open edit User
    openEditClient(data) {
      this.modalTitle = "Edit User";
      this.formData = {
        ...data,
      };
    },
    //add and edit User data
    openUserModal() {
      Object.keys(this.formData).forEach((key) => (this.formData[key] = ""));
      this.modalTitle = "Add User";
    },




    getRegionStats() {
      this.$store
          .dispatch("result/getRegionStats", this.$route.params.id)
          .then((res) => {
            console.log(res);
            this.regionStats = res;
          })
          .catch((err) => console.log(err))

          .finally(() => {
            this.loadingPage = false;

          })
      ;
    },

    getRegionSummary() {
      this.$store
          .dispatch("result/getRegionStatsSummary", this.$route.params.id)
          .then((res) => {
            console.log(res);
            this.regionSummaryStats = res.data;
          })
          .catch((err) => console.log(err));
    },

    getTurnOut(number1,number2,number3 )   {
      return  (100*(  parseInt(number1) + parseInt(number2) )/ parseInt(number3)).toFixed(1);
    }
  },
  mounted() {


    this.getRegionStats()
    this.getRegionSummary()
  },

  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    Multiselect,
    pageLoaderOne
  },
};
</script>

<template>
  <Layout>
    <!-- end table responsive -->
    <PageHeader :title="title" :items="items" />
    <!-- ADD CLIENT MODAL -->
    <div
      class="modal fade"
      id="showModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <form action="#" id="addform" @submit.prevent="addUser">
            <div class="modal-body">
              <div class="mb-3" id="modal-id">
                <label for="name" class="form-label">First Name</label>
                <input
                  type="text"
                  v-model="formData.name"
                  id="name"
                  class="form-control"
                  placeholder="Enter Name"
                  required
                />
              </div>

              <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input
                  type="email"
                  v-model="formData.email"
                  id="email"
                  class="form-control"
                  placeholder="Enter Email"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="phone" class="form-label">Phone</label>
                <input
                  type="tel"
                  v-model="formData.phone"
                  id="phone"
                  class="form-control"
                  placeholder="Enter phone"
                  required
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="role">Role</label>
                <Multiselect
                  required
                  class="form-control"
                  id="role"
                  v-model="formData.role_id"
                  :close-on-select="true"
                  :searchable="true"
                  :create-option="true"
                  :options="userRoles"
                />
              </div>
              <div class="mb-3" v-if="modalTitle !== 'Edit User'">
                <label for="avatar" class="form-label">Avatar</label>
                <input
                  type="file"
                  @change="handleAvatar"
                  ref="userAvatar"
                  id="avatar"
                  class="form-control"
                />
              </div>
              <div class="mb-3" v-if="modalTitle == 'Edit User'">
                <label for="avatar" class="form-label">Status</label>
                <Multiselect
                  required
                  class="form-control"
                  id="role"
                  v-model="formData.status_id"
                  :close-on-select="true"
                  :searchable="true"
                  :create-option="true"
                  :options="[
                    {
                      label: 'Active',
                      value: 1,
                    },
                    {
                      label: 'Inactive',
                      value: 0,
                    },
                  ]"
                />
              </div>
            </div>
            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                  id="closemodal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-success" id="add-btn">
                  {{ modalTitle }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- END ADD User MODAL -->

    <!-- User DETAILS -->
    <div
      class="modal fade"
      id="showClientDetailModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="exampleModalLabel">User Details</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-borderless mb-0">
                <tbody>
                  <tr>
                    <th class="ps-0" scope="row">User :</th>
                    <td class="text-muted">{{ UserDetailsData.name }}</td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">Email :</th>
                    <td class="text-muted">{{ UserDetailsData.email }}</td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">Phone :</th>
                    <td class="text-muted">{{ UserDetailsData.phone }}</td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">Role :</th>
                    <td class="text-muted">
                      {{ UserDetailsData.role && UserDetailsData.role.name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">Status:</th>
                    <td class="text-muted">
                      <span
                        class="badge text-uppercase"
                        :class="[
                          UserDetailsData.status_id === 1
                            ? 'badge-soft-success'
                            : 'badge-soft-danger',
                        ]"
                        >{{
                          UserDetailsData.status_id === 1
                            ? "Active"
                            : "Inactive"
                        }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
                id="closemodal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END CLIENT DETAILS -->

    <pageLoaderOne v-if="loadingPage"></pageLoaderOne>
    <div class="row" v-else>
      <div class="col-md-12">
        <div>

          {{regionSummaryStats}}




          <div class="row h-100">
            <div class="col-lg-3 col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="avatar-sm flex-shrink-0">
                                                        <span class="avatar-title bg-light text-primary rounded-circle fs-3">
                                                            <i class="ri-money-dollar-circle-fill align-middle"></i>
                                                        </span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <p class="text-uppercase fw-semibold fs-12 text-muted mb-1"> Prez -NDC</p>
                      <h4 class=" mb-0"><span class="counter-value" data-target="2390.68">{{ regionSummaryStats.prezndcvotes }}</span></h4>
                    </div>
                    <div class="flex-shrink-0 align-self-end">
                      <span class="badge badge-soft-success"><i class="ri-arrow-up-s-fill align-middle me-1"></i>0 %<span> </span></span>
                    </div>
                  </div>
                </div><!-- end card body -->
              </div><!-- end card -->
            </div><!-- end col -->
            <div class="col-lg-3 col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="avatar-sm flex-shrink-0">
                                                        <span class="avatar-title bg-light text-primary rounded-circle fs-3">
                                                            <i class="ri-arrow-up-circle-fill align-middle"></i>
                                                        </span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <p class="text-uppercase fw-semibold fs-12 text-muted mb-1"> Prez - NPP</p>
                      <h4 class=" mb-0"> <span class="counter-value" data-target="19523.25">{{ regionSummaryStats.preznppvotes }}</span></h4>
                    </div>
                    <div class="flex-shrink-0 align-self-end">
                      <span class="badge badge-soft-success"><i class="ri-arrow-up-s-fill align-middle me-1"></i>0 %<span> </span></span>
                    </div>
                  </div>
                </div><!-- end card body -->
              </div><!-- end card -->
            </div><!-- end col -->
            <div class="col-lg-3 col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="avatar-sm flex-shrink-0">
                                                        <span class="avatar-title bg-light text-primary rounded-circle fs-3">
                                                            <i class="ri-arrow-down-circle-fill align-middle"></i>
                                                        </span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">Pal - NDC</p>
                      <h4 class=" mb-0"> <span class="counter-value" data-target="14799.44">{{ regionSummaryStats.palndcvotes }}</span></h4>
                    </div>
                    <div class="flex-shrink-0 align-self-end">
                      <span class="badge badge-soft-danger"><i class="ri-arrow-down-s-fill align-middle me-1"></i>0 %<span> </span></span>
                    </div>
                  </div>
                </div><!-- end card body -->
              </div><!-- end card -->
            </div><!-- end col -->
            <div class="col-lg-3 col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="avatar-sm flex-shrink-0">
                                                        <span class="avatar-title bg-light text-primary rounded-circle fs-3">
                                                            <i class="ri-arrow-down-circle-fill align-middle"></i>
                                                        </span>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">Pal - NPP</p>
                      <h4 class=" mb-0"><span class="counter-value" data-target="14799.44">{{ regionSummaryStats.palnppvotes }}</span></h4>
                    </div>
                    <div class="flex-shrink-0 align-self-end">
                      <span class="badge badge-soft-danger"><i class="ri-arrow-down-s-fill align-middle me-1"></i> 0 %<span> </span></span>
                    </div>
                  </div>
                </div><!-- end card body -->
              </div><!-- end card -->
            </div><!-- end col -->
          </div>

          <div class="row row-cols-xxl-6 row-cols-lg-3 row-cols-sm-2 row-cols-1">
            <div class="col">
              <div class="card">
                <div class="card-body d-flex">
                  <div class="flex-grow-1">
                    <h4>

                      <span style="color: red">{{ regionSummaryStats.prez_results }}</span>

                      of {{ regionSummaryStats.stations }}</h4>
                    <h6 class="text-muted fs-13 mb-0">Prez Results</h6>
                  </div>

                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <div class="card-body d-flex">
                  <div class="flex-grow-1">
                    <h4>

                      <span style="color: red">{{ regionSummaryStats.prez_pinksheet }}</span>

                      of {{ regionSummaryStats.stations }}</h4>
                    <h6 class="text-muted fs-13 mb-0">Prez Pink Sheets</h6>
                  </div>

                </div>
              </div>
            </div>


            <div class="col">
              <div class="card">
                <div class="card-body d-flex">
                  <div class="flex-grow-1">
                    <h4>

                      <span style="color: red">{{ regionSummaryStats.prez_turnout }}%</span>

                       </h4>
                    <h6 class="text-muted fs-13 mb-0">Prez Turnout</h6>
                  </div>

                </div>
              </div>
            </div>


            <div class="col">
              <div class="card">
                <div class="card-body d-flex">
                  <div class="flex-grow-1">
                    <h4>

                      <span style="color: red">{{ regionSummaryStats.pal_results }}</span>

                      of {{ regionSummaryStats.stations }}</h4>
                    <h6 class="text-muted fs-13 mb-0">Pal Results</h6>
                  </div>

                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <div class="card-body d-flex">
                  <div class="flex-grow-1">
                    <h4>

                      <span style="color: red">{{ regionSummaryStats.palpinksheet }}</span>

                      of {{ regionSummaryStats.stations }}</h4>
                    <h6 class="text-muted fs-13 mb-0">Pal Pink Sheets</h6>
                  </div>

                </div>
              </div>
            </div>

            <div class="col">
              <div class="card">
                <div class="card-body d-flex">
                  <div class="flex-grow-1">
                    <h4>

                      <span style="color: red">


{{ regionSummaryStats.pal_turnout }}


                        %






                      </span>

                      </h4>
                    <h6 class="text-muted fs-13 mb-0">Pal Turnout</h6>
                  </div>

                </div>
              </div>
            </div>





          </div>







          <div class="card">
            <div class="card-header border-0">
              <div class="row g-4">
                <div class="col-sm-auto">
                  <div>
                    <button
                      type="button"
                      @click="exportUsers"
                      class="btn btn-primary m-2 add-btn"
                    >
                      Export
                    </button>
                  </div>
                </div>

              </div>
            </div>

            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <ul
                    class="nav nav-tabs-custom card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link active fw-semibold"
                        data-bs-toggle="tab"
                        href="#Usernav-all"
                        role="tab"
                      >
                        All

                        <span
                          class="badge badge-soft-danger align-middle rounded-pill ms-1"
                          >{{ meta.total }}</span
                        >
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">
                    <div class="my-n1 d-flex align-items-center text-muted">
                      Select
                      <div
                        id="select-content"
                        class="text-body fw-semibold px-1"
                      ></div>
                      Result
                      <button
                        type="button"
                        class="btn btn-link link-danger p-0 ms-3 shadow-none"
                        data-bs-toggle="modal"
                        data-bs-target="#removeItemModal"
                        @click="deleteMultiple"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card header -->
            <div class="card-body">
              <div class="tab-content text-muted">
                <div class="tab-pane active" id="Usernav-all" role="tabpanel">
                  <div
                    id="table-User-list-all "
                    class="table-card gridjs-border-none table-responsive"
                  >
                    <table
                      class="table align-middle table-nowrap mb-0 table-bordered"
                      id="customerTable"
                    >


                      <thead class="table-dark">
                      <tr>

                        <th scope="col" colspan="2" class="align-middle">Station / Region</th>


                        <th scope="col" colspan="2">Transmitted Results</th>

                        <th scope="col" colspan="2">Transmitted Pinksheets</th>

                        <th scope="col" colspan="1">Population</th>
                        <th scope="col" colspan="3">President</th>

                        <th scope="col" colspan="3">Parliament</th>
                        <th scope="col" colspan="1"></th>
                      </tr>
                      </thead>


                      <thead class="table-light">
                        <tr>

                          <th>Region ID</th>

                          <th>Region Name</th>
                          <th>Prez</th>
                          <th>Pal</th>
                          <th>Prez</th>
                          <th>Pal</th>
                          <th>Population</th>
                          <th>NDC</th>
                          <th>NPP</th>
                          <th>Turnout</th>
                          <th>NDC</th>
                          <th>NPP</th>
                          <th>Turnout</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody class="list form-check-all">
                        <tr
                          class="gridjs-tr"
                          v-for="(data, index) of regionStats"
                          :key="index"
                        >

                          <td class="id" hidden>
                            <a href="">{{ data.id }}</a>
                          </td>
                          <td data-column-id="name" class="gridjs-td">
                            {{ data.constid  }}
                          </td>
                          <td data-column-id="name" class="gridjs-td">



                            <router-link

                                :to="{name : 'constituency-stat', params : { id : data.constid, constname: data.constname } }"

                                class="nav-link" data-key="t-crm">
                              {{ data.constname  }}
                            </router-link>


                          </td>

                          <td data-column-id="email" class="gridjs-td">
                            {{ data.prez_results  }}
                          </td>

                          <td data-column-id="email" class="gridjs-td">
                            {{ data.pal_results  }}
                          </td>


                          <td data-column-id="email" class="gridjs-td">
                            {{ data.prez_pinksheet }}
                          </td>



                          <td data-column-id="phone" class="gridjs-td">
                            {{ data.pal_pinksheet }}
                          </td>



                          <td data-column-id="role" class="gridjs-td">
                            {{ data.station_population }}
                          </td>


                          <td data-column-id="role" class="gridjs-td">
                                                      {{ data.prezndcvotes }}
                          </td>
                          <td data-column-id="status_id" class="gridjs-td">

                              {{ data.preznppvotes }}

                          </td>

                          <td data-column-id="status_id" class="gridjs-td">



                            {{getTurnOut(data.prezndcvotes, data.preznppvotes,data.station_population )}} %

                          </td>
                          <td data-column-id="status_id" class="gridjs-td">

                            {{ data.palndcvotes }}

                          </td>

                          <td data-column-id="status_id" class="gridjs-td">

                            {{ data.palnppvotes }}

                          </td>

                          <td data-column-id="status_id" class="gridjs-td">



                            {{getTurnOut(data.palndcvotes, data.palnppvotes,data.station_population )}} %

                          </td>
                          <td data-column-id="action" class="gridjs-td">
                            <span>
                              <div class="dropdown">
                                <button
                                  class="btn btn-soft-secondary btn-sm dropdown"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i class="ri-more-fill"></i>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <button
                                      data-bs-toggle="modal"
                                      id="details-btn"
                                      data-bs-target="#showClientDetailModal"
                                      @click="UserDetailsData = { ...data }"
                                      class="dropdown-item"
                                    >
                                      <i
                                        class="ri-eye-fill align-bottom me-2 text-muted"
                                      ></i>
                                      View
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      @click="openEditClient(data)"
                                      class="dropdown-item"
                                      data-bs-toggle="modal"
                                      id="edit-btn"
                                      data-bs-target="#showModal"
                                    >
                                      <i
                                        class="ri-pencil-fill align-bottom me-2 text-muted"
                                      ></i>
                                      Edit
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </span>
                          </td>
                        </tr>











                      </tbody>

                      <tfoot class="table-light">
                      <tr>

                        <th> </th>

                        <th> </th>
                        <th>{{ regionSummaryStats.prez_results }}</th>
                        <th>{{ regionSummaryStats.pal_results }}</th>
                        <th>{{ regionSummaryStats.prez_pinksheet }}</th>
                        <th>{{ regionSummaryStats.palpinksheet }}</th>
                        <th>{{ regionSummaryStats.population }}</th>
                        <th>{{ regionSummaryStats.prezndcvotes }}</th>
                        <th> </th>
                        <th>{{ regionSummaryStats.preznppvotes }}</th>
                        <th>{{ regionSummaryStats.palndcvotes }}</th>
                        <th>{{ regionSummaryStats.palnppvotes }}</th>
                        <th> </th>
                        <th scope="col"> </th>
                      </tr>
                      </tfoot>
                    </table>

                  </div>
                </div>
                <!-- end tab pane -->

                <div class="tab-pane" id="Usernav-published" role="tabpanel">
                  <div
                    id="table-User-list-published"
                    class="table-card gridjs-border-none"
                  ></div>
                </div>
                <!-- end tab pane -->

                <div class="tab-pane" id="Usernav-draft" role="tabpanel">
                  <div class="py-4 text-center">
                    <div>
                      <lottie
                        class="avatar-xl"
                        colors="primary:#121331,secondary:#08a88a"
                        :options="defaultOptions"
                        :height="75"
                        :width="75"
                      />
                    </div>

                    <div class="mt-4">
                      <h5>Sorry! No Result Found</h5>
                    </div>
                  </div>
                </div>
                <!-- end tab pane -->
              </div>
              <!-- end tab content -->
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
